import React from "react"
import { Helmet } from "react-helmet"
import { normalize } from "styled-normalize"
import { Link } from "gatsby"
import { createGlobalStyle } from "styled-components"
import styled from "styled-components"
import "@fontsource/montserrat/800.css"
import "@fontsource/montserrat/500.css"

const IndexPage = () => {
  const GlobalStyle = createGlobalStyle`
    ${normalize}

    body {
      padding: 0;
      overflow-x: hidden;
      background-color: black;
    }
`

  return (
    <Wrapper>
      <GlobalStyle />
      <Helmet>
        <title>Rick May</title>
        <meta name="description" content="About Rick May" />
      </Helmet>
      <Content>
        <Title>Hi! My name is Rick.</Title>
        <Body>
          <Paragraph>
            My old website was really outdated, and I've just been too busy to
            create a new one. So, this placeholder will have to do!
          </Paragraph>

          <Paragraph>
            There are a lot of people in the world named Rick May. Are you sure
            you have the right one? I grew up in St. Louis, moved to Los Angeles
            working as an animator and director for tv, commercials and film. I
            also lived just outside of San Francisco for years and now up in
            Washington state where I'm creating web-apps.
          </Paragraph>

          <Paragraph>
            If you've found the Rick you are looking for, send me a LinkedIn
            message.
          </Paragraph>
          <StyledLink>
            <a href="https://www.linkedin.com/in/rickmay/">
              My LinkedIn profile.
            </a>
          </StyledLink>
        </Body>
      </Content>
    </Wrapper>
  )
}

const StyledLink = styled.div`
  margin-top: 20px;
  font-size: 15px;
  font-weight: 600;
  font-family: montserrat;
  text-decoration: none;
`

const Wrapper = styled.div`
  min-height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
`

const Content = styled.div`
  width: 90%;
  max-width: 600px;
  margin: 50px 0 50px 0;
`

const Title = styled.div`
  font-size: 50px;
  font-weight: 800;
  font-family: montserrat;
`

const Body = styled.div`
  width: 90%;
  margin-left: 5%;
`

const Paragraph = styled.div`
  margin-top: 20px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.6em;
  font-family: montserrat;
`

export default IndexPage
